import { render, staticRenderFns } from "./studyCurriculum.vue?vue&type=template&id=fdda3c16&scoped=true"
import script from "./studyCurriculum.vue?vue&type=script&lang=js"
export * from "./studyCurriculum.vue?vue&type=script&lang=js"
import style0 from "./studyCurriculum.vue?vue&type=style&index=0&id=fdda3c16&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdda3c16",
  null
  
)

export default component.exports